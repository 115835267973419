import React from "react"
import { graphql, Link } from "gatsby"
import { Box, Grid, Themed } from "theme-ui"
import LayoutGrid from "../components/layout-grid"
import Aside from "../components/aside"
import ActivistGridItem from "../components/activist-grid-item"
import Seo from "../components/seo"

const Deparment = ({ data, pageContext }) => {

  const allActivists = data.allActivistsJson.edges
  const dataFragments = data.dataJson
  const { slug, name, content } = pageContext
  const departmentsHonduras = ['atlantida', 'colon', 'cortes', 'francisco-morazan', 'intibuca', 'yoro']
  const iconColor = departmentsHonduras.includes(slug) ? `hondures` : `guatemala`
  const countryName = departmentsHonduras.includes(slug) ? `Hondures` : `Guatemala`

  return (
    <LayoutGrid>
      <Seo
        title={`${dataFragments.fragment_1} ${slug} - ${countryName}`}
        description={`${dataFragments.fragment_2} ${slug} - ${countryName}`}
      />
      <Aside>
        <Box>
          <Box
            className="text-container"
            sx={{
              h1: {
                color: `${iconColor}`
              },
              p: {
                fontSize: 1,
                mb: 3,
              },
              ".country-name": {
                fontSize: 0,
                display: `inline-block`,
                mb: 2,
              }
            }}
          >
            <Themed.a as={Link} className="country-name" to={`/${iconColor}`}>{countryName}</Themed.a>
            <Themed.h1>{name}</Themed.h1>
            <div dangerouslySetInnerHTML={{ __html: content}} />
          </Box>
        </Box>
      </Aside>
      <Grid
        as={`main`}
        columns={[2, 3]}
        gap={[2, 3]}
      >
        {allActivists.map(({ node, index }) => (
          <ActivistGridItem
            bgcolor={iconColor}
            key={node.id}
            value={node.id}
            illustrationimage={node.illustration.childImageSharp.gatsbyImageData}
            name={node.name}
            slug={node.slug}
            lawsuitspecific={node.lawsuit.specific}
          />
        ))}
      </Grid>
    </LayoutGrid>
  )
}

export default Deparment

export const query = graphql`
  query($slug: String!) {
    allActivistsJson(filter: {departmentslug: {eq: $slug}}) {
      edges {
        node {
          id
          name
          slug
          country
          illustration {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
          lawsuit {
            name
            slug
            specific
          }
        }
      }
    }
    dataJson(slug: {eq: "department"}) {
      fragment_1
      fragment_2
    }
  }
`
